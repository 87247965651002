import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

// Define an interface for what you want available from the UserContext.
interface UserContextProps {
	user: any; // You can type this more strictly if you have a user type
	userId: string;
	userMetadata: any;
	userAppdata: any;
	isPremium: boolean;
	subscriptionStatus: string;
	subscriptionExpiresAt: string;
	subscriptionInterval: string;
}

// Provide some default values.
const defaultUserContext: UserContextProps = {
	user: null,
	userId: '',
	userMetadata: null,
	userAppdata: {},
	isPremium: false,
	subscriptionStatus: 'Free Tier',
	subscriptionExpiresAt: '',
	subscriptionInterval: 'N/A',
};

// Create the context.
const UserContext = createContext<UserContextProps>(defaultUserContext);

// Create a custom hook for easier usage.
export const useUser = () => useContext(UserContext);

// Create the provider.
interface UserProviderProps {
	children: ReactNode;
}

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
	const { user } = useAuth0();
	const [userId, setUserId] = useState<string>('');
	const [userMetadata, setUserMetadata] = useState<any>({});
	const [userAppdata, setUserAppdata] = useState<any>({});
	const [isPremium, setIsPremium] = useState<boolean>(false);
	const [subscriptionStatus, setSubscriptionStatus] = useState<string>('Free Tier');
	const [subscriptionExpiresAt, setSubscriptionExpiresAt] = useState<string>('');
	const [subscriptionInterval, setSubscriptionInterval] = useState<string>('N/A');

	useEffect(() => {
		if (user) {
			// Save the basic Auth0 info.
			setUserId(user.sub || '');

			// Pull metadata (for things like first name, last name, etc.)
			const metadata = user['https://gradly-authentication-server/user_metadata'] || {};
			setUserMetadata(metadata);

			// Pull app metadata (for things like subscription details).
			const appdata = user['https://gradly-authentication-server/app_metadata'] || {};
			setUserAppdata(appdata);

			// Get subscription info from appdata.
			const { subscription_expires_at, cancel_at_period_end, interval } = appdata || {};
			setSubscriptionExpiresAt(subscription_expires_at || '');

			// Compute a friendly interval string.
			if (interval === 'month') {
				setSubscriptionInterval('Monthly');
			} else if (interval === 'annual') {
				setSubscriptionInterval('Annually');
			} else {
				setSubscriptionInterval(interval || 'N/A');
			}

			// Compute isPremium & subscriptionStatus based on expiration.
			const now = new Date();
			let periodEndDate: Date | null = null;
			if (subscription_expires_at) {
				// If it's a number, assume it’s in seconds and convert.
				periodEndDate = typeof subscription_expires_at === 'number'
					? new Date(subscription_expires_at * 1000)
					: new Date(subscription_expires_at);
			}

			const periodEndTime = periodEndDate ? periodEndDate.getTime() : 0;
			if (!subscription_expires_at || periodEndTime < now.getTime()) {
				// User's subscription has expired or is missing.
				setSubscriptionStatus('Free Tier');
				setIsPremium(false);
			} else if (periodEndDate && cancel_at_period_end) {
				// Subscription is active but scheduled for cancellation.
				setSubscriptionStatus('Cancelled');
				setIsPremium(true);
			} else if (periodEndDate) {
				// Subscription is active.
				setSubscriptionStatus('Gradly Pro');
				setIsPremium(true);
			} else {
				setSubscriptionStatus('Unknown');
				setIsPremium(false);
			}
		}
	}, [user]);

	return (
		<UserContext.Provider
			value={{
				user,
				userId,
				userMetadata,
				userAppdata,
				isPremium,
				subscriptionStatus,
				subscriptionExpiresAt,
				subscriptionInterval,
			}}
		>
			{children}
		</UserContext.Provider>
	);
};

export default UserContext;
